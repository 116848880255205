<template>
  <div>
    <table-list
      class="tableList"
      :title="tableTitle"
      :loading="loading"
      :search-form="searchForm(this)"
      :data="incomeList"
      :options="{ selection: true }"
      :columns="columns(this)"
      :btns="btns(this)"
      :pager="pager"
      @search="onSearch"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
    ></table-list>
  </div>
</template>

<script>
//表格标题
const tableTitle = '提现记录'
// 顶部按钮
const btns = _this => [
  {
    label: '导出',
    method: _this.handleExport,
    type: 'danger',
  },
]
// 搜索框配置
const searchForm = _this => [
  {
    label: '收支类型',
    prop: 'reType',
    type: 'select',
    children: _this.reType.map(item => ({ value: item.key, label: item.value })),
  },
  {
    label: '交易类型',
    prop: 'serialType',
    type: 'select',
    children: _this.serialType.map(item => ({ value: item.key, label: item.value })),
  },
  {
    label: '支付时间',
    prop: 'time',
    type: 'picker',
  },
]
// 表格列配置
const columns = () => [
  {
    label: '流水编号',
    prop: 'serialCode',
  },
  {
    prop: 'orderId',
    label: '订单编号',
  },
  {
    prop: 'showConsumeAmount',
    label: '流水金额',
  },
  {
    prop: 'actualAmount',
    label: '余额',
    render: (h, { row }) => {
      return h('div', [h('div', row.actualAmount ? row.actualAmount.toFixed(2) : '')])
    },
  },
  {
    prop: 'payTypeName',
    label: '支付渠道',
  },
  {
    prop: 'serialTypeName',
    label: '交易类型',
  },
  {
    prop: 'showSerialTypeName',
    label: '收支类型',
  },
  {
    prop: 'createTimeShow',
    label: '创建时间',
  },
  {
    prop: 'payTimeShow',
    label: '支付时间',
  },
]
const payType = [
  { key: 'ALIPAY', value: '支付宝' },
  { key: 'WXPAY', value: '微信' },
  { key: 'THIRD', value: '公对公' },
]
const payStatus = [
  { key: 'SUCCESS', value: '已完成' },
  { key: 'REFUND', value: '已退款' },
]
const reType = [
  { key: 1, value: '收入' },
  { key: 2, value: '支出' },
]
const serialType = [
  { key: 'INVEST', value: '充值' },
  { key: 'CONSUME', value: '消费' },
  { key: 'CASH', value: '提现' },
  { key: 'REFUND', value: '退款' },
]
import to from 'await-to'
import { getIncomeList } from '@/api/finance'
import TableList from '@/components/TableList'
export default {
  components: {
    TableList,
  },
  data() {
    return {
      tableTitle,
      searchForm,
      columns,
      btns,
      payType,
      payStatus,
      serialType,
      reType,
      pager: {
        total: 0,
        size: 10,
        current: 1,
        orderStatus: '',
        reType: 0,
        payType: '',
        orderId: '',
        serialCode: '',
        serialType: '',
        beginCreateTime: '',
        endCreateTime: '',
      },
      loading: false,
      incomeList: [],
    }
  },
  created() {
    this.getIncomeListData()
  },
  methods: {
    async getIncomeListData() {
      this.loading = true
      const [res, err] = await to(getIncomeList(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      this.loading = false
      this.incomeList = res.data.list
      this.pager.total = res.data.total
    },
    onSearch(params) {
      this.pager.current = 1
      this.pager.reType = params.reType
      this.pager.payCode = params.payCode
      this.pager.orderId = params.orderId
      this.pager.payType = params.payType
      this.pager.orderStatus = params.orderStatus
      this.pager.serialType = params.serialType
      if (params.time && params.time.length !== 0) {
        this.pager.beginCreateTime = params.time[0]
        this.pager.endCreateTime = params.time[1]
      }
      this.getIncomeListData()
    },
    async handleExport() {
      this.pager.size = 10000
      const [res, err] = await to(getIncomeList(this.pager))
      if (err) return this.$message({ type: 'error', message: err.msg })
      // 导出表格的表头设置
      let allColumns = columns(this)
      var columnNames = []
      var columnValues = []
      for (var i = 0; i < allColumns.length; i++) {
        columnNames[i] = allColumns[i].label
        columnValues[i] = allColumns[i].prop
      }
      require.ensure([], () => {
        const { export_json_to_excel } = require('@/vendor/Export2Excel')
        const tHeader = columnNames
        const filterVal = columnValues
        const list = res.data.list
        const data = this.formatJson(filterVal, list)
        export_json_to_excel(tHeader, data, '收支明细')
      })
      this.pager.size = 10
    },
    formatJson(filterVal, jsonData) {
      return jsonData.map(v => filterVal.map(j => v[j]))
    },
    // 分页
    handleSizeChange(size) {
      this.pager.current = 1
      this.pager.size = size
      this.getIncomeListData()
    },
    handleCurrentChange(current) {
      this.pager.current = current
      this.getIncomeListData()
    },
  },
}
</script>

<style scoped lang="scss">
.tableList {
  margin-top: 30px;
}
.topInfoNews {
  padding-left: 24px;
  padding-top: 20px;
  height: 122px;
  .title {
    font-size: 14px;
    color: #606266;
  }
  .money {
    font-size: 24px;
    margin-top: 10px;
    margin-bottom: 20px;
  }
  .textBtn {
    color: #ff7b33;
    text-decoration: underline;
    font-size: 14px;
    cursor: pointer;
  }
}
.first {
  background-color: #e5f6ff;
  .money {
    color: #2c71ff;
  }
}
.two {
  background: #f6f3ff;
  .money {
    color: #604bba;
  }
}
.three {
  background: #e2f6e9;
  .money {
    color: #348d50;
  }
}
.four {
  background: #fff5f0;
  .money {
    color: #ff5c34;
  }
}
</style>
